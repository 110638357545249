import { Button, Row, Col, Form, Input } from 'antd';
import React, { useState } from 'react';
import * as AWS from 'aws-sdk';
import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser,
} from 'amazon-cognito-identity-js'
import { useSelector, useDispatch } from 'react-redux'
import { USER_POOL_ID, CLIENT_ID, IDENTITY_POOL_ID } from '../config/config'
import './login.css'

const userPool = new CognitoUserPool({
    UserPoolId: USER_POOL_ID,
    ClientId: CLIENT_ID,
})

export default () => {

    const dispatch = useDispatch()
    const s3 = useSelector(state => state.s3)
    const [isFirstLogin, setIsFirstLogin] = useState(false)
    const [isSubmited, setIsSubmited] = useState(false)
    const [loginError, setLoginError] = useState('')

    const onFinishFailed = (errorInfo) => {
        console.log(errorInfo)
        // setErrorInfo(errorInfo)
    }

    const setUserProfile = (userProfile) => {
        dispatch({ type: 'SET_USER_PROFILE', userProfile })
    }

    const setS3 = (s3) => {
        dispatch({ type: 'SET_S3', s3 })
    }

    const _authenticate = (values) => {
        setIsSubmited(true)
        setLoginError('')
        const { username, password } = values
        window.cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool
        });

        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        })

        window.cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                setIsSubmited(false);
                // console.log(result.getIdToken().payload)
                setUserProfile(result.getIdToken().payload)
                // var accessToken = result.getAccessToken().getJwtToken();
                // console.log(accessToken);
                //POTENTIAL: Region needs to be set if not already set previously elsewhere.
                AWS.config.region = 'us-east-1'

                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: IDENTITY_POOL_ID, // your identity pool id here
                    Logins: {
                        // Change the key below according to the specific region your user pool is in.
                        'cognito-idp.us-east-1.amazonaws.com/us-east-1_kekA17hMv': result
                            .getIdToken()
                            .getJwtToken(),
                    },
                });

                // //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
                AWS.config.credentials.refresh(error => {
                    if (error) {
                        console.error(error)
                    } else {
                        // Instantiate aws sdk service objects now that the credentials have been updated.
                        console.log('Successfully logged!')
                        if (!s3) {
                            const s3Instance = new AWS.S3({
                                apiVersion: '2006-03-01',
                            })
                            setS3(s3Instance)
                        }
                    }
                });
            },

            onFailure: function (err) {
                setLoginError(err.message || JSON.stringify(err))
                setIsSubmited(false)
                setUserProfile(null)
            },

            newPasswordRequired: function (userAttributes, requiredAttributes) {
                // the api doesn't accept this field back
                delete userAttributes.email_verified;
                delete userAttributes.email; // <--- add this line
                // store userAttributes on global variable
                // console.log(userAttributes, requiredAttributes)
                window.sessionUserAttributes = userAttributes;
                setIsFirstLogin(true)
                setLoginError('Please change your password on first login.')
                setIsSubmited(false)
            }
        })

    }

    const _completeNewPasswordChallenge = (values) => {
        setIsSubmited(true)
        setLoginError('')

        const { username, newPassword } = values

        window.cognitoUser.completeNewPasswordChallenge(newPassword, window.sessionUserAttributes, {
            onSuccess: function (result) {
                // console.log(window.sessionUserAttributes)
                setIsSubmited(false);
                // console.log(result.getIdToken().payload)
                setUserProfile(result.getIdToken().payload)
                // var accessToken = result.getAccessToken().getJwtToken();
                // console.log(accessToken);
                //POTENTIAL: Region needs to be set if not already set previously elsewhere.
                AWS.config.region = 'us-east-1'

                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: IDENTITY_POOL_ID, // your identity pool id here
                    Logins: {
                        // Change the key below according to the specific region your user pool is in.
                        'cognito-idp.us-east-1.amazonaws.com/us-east-1_kekA17hMv': result
                            .getIdToken()
                            .getJwtToken(),
                    },
                });

                // //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
                AWS.config.credentials.refresh(error => {
                    if (error) {
                        console.error(error)
                    } else {
                        // Instantiate aws sdk service objects now that the credentials have been updated.
                        console.log('Successfully logged!')
                        if (!s3) {
                            const s3Instance = new AWS.S3({
                                apiVersion: '2006-03-01',
                            })
                            setS3(s3Instance)
                        }
                    }
                });
            },

            onFailure: function (err) {
                console.log(window.sessionUserAttributes)
                setLoginError(err.message || JSON.stringify(err))
                setIsSubmited(false)
                setUserProfile(null)
            }
        });
    }

    return (
        <div className="login-container">
            {isFirstLogin ?
                <Form
                    className="first-login-form"
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={_completeNewPasswordChallenge}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="login-title">Plant Identifier Assets Management</div>
                    <div className="login-noti">{loginError}</div>
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="New password"
                        name="newPassword"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={isSubmited} disabled={isSubmited}>
                            Save
                        </Button>
                    </Form.Item>
                    <Row>
                        <Col span={8}>
                            Password requirements
                        </Col>
                        <Col span={16}>
                            <ul>
                                <li>Password minimum length 8 character(s)</li>
                                <li>Contains at least 1 number, 1 uppercase letter, 1 lowercase letter</li>
                                <li>{`Contains at least 1 special character (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ \` + =)`}</li>
                            </ul>
                        </Col>
                    </Row>
                </Form>
                : <Form
                    className="login-form"
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={_authenticate}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="login-title">Plant Identifier Assets Management</div>
                    <div className="login-noti">{loginError}</div>
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={isSubmited} disabled={isSubmited}>
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            }

        </div >
    );
};