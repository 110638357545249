import React, { useEffect, useState, useRef } from 'react';
import {
    Layout,
    Avatar,
    Space,
    Button,
    notification,
    Typography,
    Spin,
    Skeleton,
    Tabs,
} from 'antd';
import { SyncOutlined } from '@ant-design/icons'
import { VariableSizeTree as Tree } from 'react-vtree'
import ImageViewer from './ImageViewer';
import { useSelector, useDispatch } from 'react-redux'
import diseasesDescriptionVi from './assets/diseases-vi.final.json'
import diseasesDescriptionEn from './assets/diseases-en.json'
import './index.css'
import { RAW_BUCKET } from './config'
import Login from '../login/Login';

const { Sider, Header, Content } = Layout

const diseasesPrefix = 'plant-identifier/diseaseImage/'

export default () => {

    const userProfile = useSelector(state => state.userProfile)
    const s3 = useSelector(state => state.s3)
    const [isSubmitedDelete, setIsSubmitedDelete] = useState(false)
    const [diseases, setDiseases] = useState(null)
    const [currentFolder, setCurrentFolder] = useState(null)
    const [rawImages, setRawImages] = useState([])
    const [selectedImages, setSelectedImages] = useState([])
    const [isFetchingData, setIsFetchingData] = useState(false)
    const dispatch = useDispatch()
    const description = {
        vi: diseasesDescriptionVi.find(x => x.title === currentFolder?.trim()),
        en: diseasesDescriptionEn.find(x => x.title === currentFolder?.trim())
    }
    console.log(description)
    useEffect(() => {
        if (s3) {
            listDiseases('plant-identifier/diseaseImage/')
        }
    }, [s3])

    const listDiseases = () => {
        if (s3) {
            s3.listObjectsV2({ Bucket: RAW_BUCKET, Prefix: diseasesPrefix, Delimiter: '/' }, function (err, data) {
                if (err) {
                    console.log(err, err.stack)
                    notification({ message: 'There was an error listing your albums: ' + err.stack });
                }
                else {
                    // console.log(data.CommonPrefixes)
                    setDiseases(data.CommonPrefixes)
                }
            });

        }

    }

    const listObjects = (uri, type) => {
        if (s3) {
            s3.listObjectsV2({ Bucket: RAW_BUCKET, Prefix: uri, Delimiter: '/' }, function (err, data) {
                if (err)
                    notification({ message: 'There was an error listing your albums: ' + err.message });
                else {
                    setRawImages(data.Contents)
                    setIsFetchingData(false)
                }
            });
        }

    }

    const tree = {
        name: 'All diseases',
        id: 'root-1',
        children: (diseases ?? []).map((disease, index) => ({
            id: index,
            name: disease.Prefix.replace(diseasesPrefix, '').replace('/', '')
        }))
    };

    function* treeWalker(refresh) {
        let stack = [];

        stack = tree.children
            .map((node) => ({
                nestingLevel: 0,
                node
            })).reverse()

        while (stack.length !== 0) {
            const {
                node: { children = [], id, name },
                nestingLevel,
            } = stack.pop();

            const isOpened = yield refresh
                ? {
                    defaultHeight: 30,
                    id,
                    isLeaf: children.length === 0,
                    isOpenByDefault: true,
                    name,
                    nestingLevel,
                }
                : id;

            if (children.length !== 0 && isOpened) {
                for (let i = children.length - 1; i >= 0; i--) {
                    stack.push({
                        nestingLevel: nestingLevel + 1,
                        node: children[i],
                    });
                }
            }
        }
    }

    const Node = ({ data: { isLeaf, name }, height, isOpen, style, toggle }) => (
        <div style={style}>
            <div className='tree-item' style={name !== currentFolder ? {} : { color: '#eb5f07' }} onClick={() => _getAssets(name)}>{name}</div>
        </div>
    );

    const _deleteSelectedImages = async () => {
        if (selectedImages.length === 0)
            return
        // console.log(selectedImages)
        setIsSubmitedDelete(true)
        const params = {
            Bucket: RAW_BUCKET,
            Delete: {
                Objects: selectedImages.map(x => ({ Key: x.Key })),
            }
        };
        s3.deleteObjects(params, function (err, data) {
            if (err) {
                console.log(err, err.stack)
                notification.error({ message: 'Deleted failed!' });
            } // an error occurred
            else {
                // console.log(data)
                const deletedKeys = data.Deleted.map(x => x.Key)
                const newRawImages = rawImages.filter(x => deletedKeys.indexOf(x.Key) < 0)
                setRawImages(newRawImages)
                setSelectedImages([])
                notification.success({ message: 'Deleted successfully!' });
            }
            setIsSubmitedDelete(false)
        });

    }

    const _deleteImages = async () => {
        if (window.deleteImages.length === 0)
            return
        // console.log(selectedImages)
        const params = {
            Bucket: RAW_BUCKET,
            Delete: {
                Objects: window.deleteImages.map(x => ({ Key: x.Key })),
            }
        };
        s3.deleteObjects(params, function (err, data) {
            if (err) {
                console.log(err, err.stack)
                notification.error({ message: 'Deleted failed!' });
            } // an error occurred
            else {
                // console.log(data)
                const deletedKeys = data.Deleted.map(x => x.Key)
                const newRawImages = rawImages.filter(x => deletedKeys.indexOf(x.Key) < 0)
                setRawImages(newRawImages)
                // setSelectedImages([])
                notification.success({ message: 'Deleted successfully!' });
            }
        });

    }

    const _getAssets = async (name) => {
        setIsFetchingData(true)
        setCurrentFolder(name)
        setSelectedImages([])
        setRawImages([])
        listObjects(`${diseasesPrefix}${name}/`, 'raw')
    }

    const _onSelectImage = (imageObject) => {
        // console.log(imageObject)
        let newSelectedImages = [...selectedImages]
        const selectedKeys = newSelectedImages.map(x => x.Key)
        const isSelected = selectedKeys.indexOf(imageObject.Key) > -1
        if (!isSelected) {
            console.log('push')
            newSelectedImages.push(imageObject)
        }
        if (isSelected) {
            console.log('remove')
            newSelectedImages = newSelectedImages.filter(x => x.Key !== imageObject.Key)
        }
        setSelectedImages(newSelectedImages)
    }

    // console.log(diseases)
    return (
        <>
            {
                userProfile ?
                    <div className="container">
                        <Layout>
                            <Header className='header-container'>
                                {/* <Avatar src={Logo} size={50} shape="square" /> */}
                                <span className='header-title'>Plant Identifier Assets Management</span>
                                <Space style={{ float: 'right' }}>
                                    <span style={{ fontWeight: 600, color: '#fff', marginRight: 8 }}>
                                        {userProfile['cognito:username']}
                                    </span>
                                    <Avatar src="https://avatars1.githubusercontent.com/u/8186664?s=460&v=4" />
                                    <Button type="primary" onClick={() => dispatch({ type: 'SET_USER_PROFILE', userProfile: null })}>
                                        Logout
                                    </Button>
                                </Space>
                            </Header>
                            <Layout>
                                <Sider className="sider-menu" theme="light" trigger={null} width={200}>
                                    <Skeleton loading={!diseases} active>
                                        <Tree treeWalker={treeWalker} height={window.innerHeight - 64} width={200}>
                                            {Node}
                                        </Tree>
                                    </Skeleton>
                                </Sider>
                                <Layout>
                                    <Content className="main-container" style={{ height: window.innerHeight - 44 }}>
                                        <Spin
                                            tip='Listing items...' spinning={isFetchingData}
                                            indicator={<SyncOutlined spin />}
                                        >
                                            <div style={{ minHeight: 1000 }}>
                                                <Typography.Title level={3}>{currentFolder}: {rawImages.length} Raw Images</Typography.Title>

                                                <Space direction='horizontal' wrap>
                                                    {rawImages.sort((a, b) => {
                                                        const intA = parseInt(a.Key.split('/').slice(-1)[0].split('.')[0])
                                                        const intB = parseInt(b.Key.split('/').slice(-1)[0].split('.')[0])
                                                        return intA - intB
                                                    }).map(img => (
                                                        <ImageViewer onSelectImage={_onSelectImage} image={img} selectedImages={selectedImages} />
                                                    ))}
                                                </Space>
                                            </div>
                                        </Spin>
                                        <div className="select-summary">
                                            {selectedImages.length} images selected
                                            <Space style={{ float: 'right' }}>
                                                <Button type="primary">Use in production</Button>
                                                <Button type="primary" loading={isSubmitedDelete} danger onClick={_deleteSelectedImages}>Delete</Button>
                                            </Space>
                                        </div>
                                    </Content>
                                    <Sider className="right-sider" theme="light" width='300' style={{ height: window.innerHeight - 44 }}>
                                        <Tabs defaultActiveKey="1">
                                            <Tabs.TabPane tab="Vietnamese" key="1">
                                                <h3>{currentFolder}</h3>
                                                <h4>Mô tả:</h4>
                                                <p>{description?.vi?.text}</p>
                                                <h4>Triệu chứng:</h4>
                                                <p>{description?.vi?.symptoms}</p>
                                                <h4>Chi tiết bệnh:</h4>
                                                {
                                                    description?.vi?.articleDescription?.map(item => (
                                                        <p>{item.title}: {item.text}</p>
                                                    ))
                                                }
                                                <p>{description?.vi?.detailContent?.startText}</p>
                                                <p>{description?.vi?.detailContent?.finalText}</p>
                                                {
                                                    description?.vi?.detailContent?.externalSignsOfDamage?.map(item => (
                                                        <p>{item}</p>
                                                    ))
                                                }
                                            </Tabs.TabPane>
                                            <Tabs.TabPane tab="English" key="2">
                                                <h3>{currentFolder}</h3>
                                                <h4>Description:</h4>
                                                <p>{description?.en?.text}</p>
                                                <h4>Symptoms:</h4>
                                                <p>{description?.en?.symptoms}</p>
                                                <h4>Details:</h4>
                                                {
                                                    description?.en?.articleDescriptions?.map(item => (
                                                        <p>{item.title}: {item.text}</p>
                                                    ))
                                                }
                                                <p>{description?.en?.detailedContent?.startText}</p>
                                                <p>{description?.en?.detailedContent?.finalText}</p>
                                                {
                                                    description?.en?.detailedContent?.externalSignsOfDamage?.map(item => (
                                                        <p>{item}</p>
                                                    ))
                                                }

                                            </Tabs.TabPane>
                                        </Tabs>

                                    </Sider>
                                </Layout>
                            </Layout>
                        </Layout>
                    </div>
                    : <Login />
            }
            <Button onClick={_deleteImages} id='hidden-delete-btn'></Button>
        </>
    )
}