const initialState = {
    userProfile: null,
    s3: null,
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_USER_PROFILE': {
            return Object.assign({}, state, {
                userProfile: action.userProfile
            })
        }
        case 'SET_S3': {
            return Object.assign({}, state, {
                s3: action.s3
            })
        }
        default:
            return state
    }
}