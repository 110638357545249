import React from 'react';
import { Layout, Space, Avatar, Row, Col } from 'antd'
import { Link } from 'react-router-dom';

const { Header, Content } = Layout
const listApps = [
    { name: 'Rock Identifier', url: 'rock-identifier' },
    { name: 'Insect Identifier', url: 'insect-identifier' },
    { name: 'Mushroom Identifier', url: 'mushroom-identifier' },
    { name: 'Plant Identifier', url: 'plant-identifier' },
    { name: 'Bird Identifier', url: 'bird-identifier' },
    { name: 'Fish Identifier', url: 'fish-identifier' },
    { name: 'Pixel Coloring', url: 'pixel-coloring' },
]

export default () => {
    return (
        <div className="container">
            <Layout>
                <Header className='header-container'>
                    {/* <Avatar src={Logo} size={50} shape="square" /> */}
                    <span className='header-title'>App Console</span>
                </Header>
                <Content>
                    <div className='list-apps'>
                        <Row gutter={8}>
                            { 
                                listApps.map((app, index) => (
                                    <Col span={8} key={index}>
                                        <Link className='app-item' to={app.url}>{app.name}</Link>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                </Content>
            </Layout>
        </div>
    )
}