import React from 'react';
import './App.less';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ColoringApp from './components/coloring-app';
import PlantIdentifier from './components/plant-identifier';
import Home from './components/home';

const App = () => (
  <Router>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/pixel-coloring" element={<ColoringApp />} />
      <Route path="/plant-identifier" element={<PlantIdentifier />} />
    </Routes>
  </Router>
);

export default App;